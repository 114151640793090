var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.editable && _vm.param.selfInspectionStatusCd == "SISC000001"
        ? _c(
            "div",
            { staticClass: "col float-right" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable &&
                  _vm.param.selfInspectionResultId &&
                  _vm.param.selfInspectionStatusCd == "SISC000001"
                    ? _c("c-btn", {
                        attrs: { label: "LBLREMOVE", icon: "remove" },
                        on: { btnClicked: _vm.deleteData },
                      })
                    : _vm._e(),
                  _c("c-btn", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value:
                          _vm.editable &&
                          _vm.param.selfInspectionResultId &&
                          _vm.param.selfInspectionStatusCd == "SISC000001",
                        expression:
                          "editable && param.selfInspectionResultId && param.selfInspectionStatusCd == 'SISC000001'",
                      },
                    ],
                    attrs: {
                      url: _vm.saveUrl,
                      isSubmit: _vm.isSave,
                      param: _vm.data,
                      mappingType: _vm.mappingType,
                      label: "계획완료",
                      icon: "assignment_turned_in",
                      color: "orange-6",
                    },
                    on: {
                      beforeAction: _vm.saveDataEnd,
                      btnCallback: _vm.saveCallbackEnd,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("c-tab", {
        attrs: { type: "vertical", tabItems: _vm.tabItems, height: _vm.height },
        on: {
          "update:height": function ($event) {
            _vm.height = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "default",
            fn: function (tab) {
              return [
                _c(tab.component, {
                  tag: "component",
                  attrs: { popupParam: _vm.param },
                  on: {
                    "update:popupParam": function ($event) {
                      _vm.param = $event
                    },
                    "update:popup-param": function ($event) {
                      _vm.param = $event
                    },
                  },
                }),
              ]
            },
          },
        ]),
        model: {
          value: _vm.tab,
          callback: function ($$v) {
            _vm.tab = $$v
          },
          expression: "tab",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }